import { usePage } from "@inertiajs/vue3"

/**
 * havePermission returns true only if user has the passed permission
 * @param  {string} permission
 * @return {boolean}
 */
export const havePermission = permission => {
  const userPermissions = usePage()?.props?.auth?.user?.permissions
  if (!userPermissions) return false

  return userPermissions.includes(permission)
}

/**
 * haveAllPermissions returns true only if user has all the passed permissions
 * @param  {string[]} permissions
 * @return {boolean}
 */
export const haveAllPermissions = permissions => {
  const userPermissions = usePage()?.props?.auth?.user?.permissions
  if (!userPermissions) return false

  for (const permission of permissions) {
    if (!userPermissions.includes(permission)) return false
  }
  return true
}

/**
 * haveAnyPermission returns true only if user has any of the passed permissions
 * @param  {string[]} permissions
 * @return {boolean}
 */
export const haveAnyPermission = permissions => {
  const userPermissions = usePage()?.props?.auth?.user?.permissions
  if (!userPermissions) return false

  return permissions.some(permission => userPermissions.includes(permission))
}
